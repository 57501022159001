import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

// Import Pages
const Landing = lazy(() => import("../Pages/Landing")); // Load child component lazy
const Faq = lazy(() => import("../Pages/Faq")); // Load child component lazy
const Privacy = lazy(() => import("../Pages/Privacy")); // Load child component lazy
const AgeYardBox = lazy(() => import("../Pages/AgeYardBox")); // Load child component lazy

const Routes = () => {
    
    return (
        <Suspense fallback={'<div>Loading...</div>'}>
            <Switch>
                <Route path={'/'} component={Landing} exact />
                <Route path={'/age-yard-box'} component={Landing} exact />
                <Route path={'/age-yard-box/faq'} component={Faq} exact />
                <Route path={'/privacy-policy'} component={Privacy} exact />
                <Route path={'/AgeYardBox'} component={AgeYardBox} exact />
            </Switch>
        </Suspense>
    );
};

export default Routes;