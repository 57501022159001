import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'; // Loads semantic deafult css
import './Css/developer.css';
import Front from "./Layouts/Front"; // Load Layout

function App() {
    return (
        <Router>
            <Front />
        </Router>
    );
}

export default App;
