import React, { lazy, Suspense } from 'react';
import '../Css/style.css'; // Imort Front CSS
import Routes from "../Router/Routes"; // Load Routes
const Header = lazy(() => import("../Components/Header")); // Load child component lazy
const Footer = lazy(() => import("../Components/Footer")); // Load child component lazy

const Front = () => {
    return (
        <div className="wrapper">
            <Suspense fallback={'<div>Loading...</div>'}>
                <Header />
                <Routes />
                <Footer />
            </Suspense>
        </div>
    );
};

export default Front;